/* Sectional Anatomy Viewer
 *
 * Copyright (C) 2012 - 2016 Tobias Rautenkranz
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

//--- typed Arrays -----
define('util/array',[],function () {
  "use strict";
  var mkNativeArray = function (arrayType) {
    var ArrayConstructor = window[arrayType];
    return ArrayConstructor ?
      function (sizeOrArray) {
        return new ArrayConstructor(sizeOrArray);
      }	:
    function (sizeOrArray) {
      return typeof(sizeOrArray) === "number" ?
        new Array(sizeOrArray) : sizeOrArray;
    };
  };
  var mkUint8Array = mkNativeArray("Uint8ClampedArray");
  var mkUint16Array = mkNativeArray("Uint16Array");
  var mkUint32Array = mkNativeArray("Uint32Array");

  var mkFloat32Array = mkNativeArray("Float32Array");

  var mkUintArray = function (bits, array) {
    switch (bits) {
      case 8:
        return mkUint8Array(array);
      case 16:
        return mkUint16Array(array);
    }
    throw new Error("Do not know how to make a array of " + bits + " bits");
  };

  return {
    mkUint8Array: mkUint8Array,
    mkUint16Array: mkUint16Array,
    mkUint32Array: mkUint32Array,

    mkUintArray: mkUintArray,

    mkFloat32Array: mkFloat32Array,

    hasUint32Array: !!window.Uint32Array,

    fill: function (array, value) {
      if (array.fill) {
        array.fill(value);
      } else {
        for (var i=0, l=array.length; i<l; i++) {
          array[i] = value;
        }
      }
    },

    set: function (array, otherArray) {
      if (array.set) {
        array.set(otherArray);
      } else {
        for (var i=0; i<array.length; i++) {
          array[i] = otherArray[i];
        }
      }
    }
  };
});

