/* Sectional Anatomy Study Browser
 *
 * Copyright (C) 2012 - 2016 Tobias Rautenkranz
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

define('studies-browser',["studies", "ui/animate", "util/utils", "ui/suggestions"],
  function (sstudies, animate, utils, suggestions) {
    "use strict";

    var useAnimation = false; // do not use animation for initial filtering

    var studiesList;
    var studies;
    var language;
    var searchLoading;
    var suggestionsDisplay;

    function filterStudies(searchString) {
      if (searchString) {
        searchLoading.style.display = "";

        var studiesSearched = function () {
          searchLoading.style.display = "none";
          if (hasResults()) {
            suggestionsDisplay.hide();
          } else {
            var searchStrings = utils.splitSearchString(searchString);

            var onSuggest = function (sug) {
              suggestionsDisplay.show(sug, searchStrings);
            };
            var onNoSuggest = function () {
              suggestionsDisplay.hide();
            };

            var showSuggestions = suggestions.makeProcessLabels(searchStrings,
                onSuggest, onNoSuggest);

            for (var i=0; i<studies.length; i++) {
              sstudies.getLabels(studies[i], language, showSuggestions);
            }
          }
        };

        showResults(sstudies.findStudies(studies, searchString));
        sstudies.filterStudies(searchString, studies, language,
            showStudyResults, studiesSearched);
      } else {
        for (var i=0; i<studiesList.length; i++) {
          showResult(i);
          var studyNode = studiesList[i];
          getStudyAnatomyList(studyNode).innerHTML = "<br>";

          var studyURL = studies[i].url;
          studyURL += utils.makeURLHash({"lang": language});
          getStudyLink(studyNode).href = studyURL;
        }
      }
    }

    function showStudyResults(searchStrings, searchResults, study, labels) {
      var studyNode = studiesList[study.id];
      var ids = searchResults.ids;

      if (ids.length > 0) {
        var resultsHTML = "";
        var extraURLArgs = "";
        extraURLArgs = "#lang=" + language;
        for (var i=0; i<Math.min(4, ids.length); i++) {
          var id = ids[i];
          resultsHTML += "<a href='" + study.url +
            utils.makeURLHash({"id": id, "lang": language}) + "'>" +
            highlighedLabelHTML(labels[id], searchResults.regexs) + "</a> ";
        }
        getStudyAnatomyList(studyNode).innerHTML = resultsHTML;

        var searchURL = study.url +
          utils.makeURLHash({"q": searchStrings.join(" "), "lang": language});
        getStudyLink(studyNode).href = searchURL;

        if (ids.length > 5) {
          getStudyAnatomyList(studyNode).innerHTML +=
            " <a href='" + searchURL + "'>&hellip;<a>";
        }
        showResult(study.id);
      } else {
        var studyURL = study.url;
        studyURL += utils.makeURLHash({"lang": language});
        getStudyLink(studyNode).href = studyURL;
        getStudyAnatomyList(studyNode).innerHTML = "<br>";
      }
    }
    /** Initialize */
    function init() {
      language = document.documentElement.lang || "en";

      function makeNavigateToStudyFunction (study) {
        return function (e) {
          if (this == e.target) {
            window.location = study.url + utils.makeURLHash({"lang": language});
          }
        };
      }

      studies = sstudies.getStudies(document.getElementById("studies"));

      var search = document.getElementById("search");
      suggestionsDisplay = suggestions.makeDisplayElement(search.parentElement,
          function(s) { search.value = s; filterStudies(search.value); });

      // search result anatomy words
      studiesList = document.getElementById("studies").children;
      for (var i=0; i<studiesList.length; i++) {
        studiesList[i].isVisible = true;

        var li = studiesList[i];

        if (li.addEventListener) {
          li.addEventListener("click", makeNavigateToStudyFunction(studies[i]));
        }
        li.style.cursor = "pointer";

        li.children[0].insertBefore(document.createElement("p"),
            li.children[0].children[1]);
      }
      searchLoading = new Image();
      searchLoading.src = "/img/load16.gif";
      searchLoading.style.display = "none";
      searchLoading.width = "16";
      searchLoading.height = "16";
      search.parentElement.insertBefore(searchLoading, search.nextSibling);

      if (search.addEventListener) {
        /* oninput is buggy on ie9; i.e. deleting/backspacing chars does not
         * fire the event.
         * There is no easy workaround (=> update your browser)
         */
        search.addEventListener("input",
            function (){ filterStudies(search.value); });
      }
      filterStudies(search.value);
      useAnimation = true;
    }

    function showResult(id) {
      studiesList[id].isVisible = true;
      studiesList[id].style.display=""; //fallback for missing animation support
      if (useAnimation) {
        animate.setAnimationProperty(studiesList[id], "fadeIn 0.2s");
      }
    }
    function hideResult(id) {
      studiesList[id].isVisible = false;
      animate.hideOnAnimationEnd(studiesList[id], "fadeOut");
      animate.setAnimationProperty(studiesList[id], "fadeOut 0.2s");
    }
    function hasResults() {
      for (var i=0; i<studiesList.length; i++) {
        if (studiesList[i].isVisible) {
          return true;
        }
      }

      return false;
    }

    /** Shows the studies specified by IDS. */
    function showResults(ids) {
      function uniq(array) {
        var lastElement;
        return array.filter(function(value, index) {
          if (lastElement === value) {
            return false;
          } else { lastElement = value;
            return true; }
        });
      }
      ids = uniq(ids.sort());
      var showId=0;
      for (var i=0; i<studiesList.length; i++) {
        if (showId<ids.length && i === ids[showId]) {
          showId++;
          showResult(i);
        } else {
          hideResult(i);
        }
      }
    }

    function getStudyAnatomyList(studyNode) {
      return studyNode.children[0].children[1];
    }
    function getStudyLink(studyNode) {
      return studyNode.children[0];
    }

    function highlighedLabelHTML(label, regexs) {
      var html = label;
      for (var i=0; i<regexs.length; i++) {
        html = html.replace(regexs[i], "<span class=hl>$&</span>", "g");
      }
      return html;
    }

    return {
      init: init
    };
  });

