/* Sectional Anatomy Viewer
 *
 * Copyright (C) 2012 - 2015 Tobias Rautenkranz
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

define('util/cont',[],function () {
  "use strict";
  /** Wait for several jobs to finish the call cont. */
  var CollectiveContinuation = function (cont) {
    function makeContinueFunction (thisContinuation) {
      return function () {
        thisContinuation.waitCount--;
        thisContinuation.tryContinue();
      };
    }

    this.waitCount = 0;
    this.cont = cont;

    this.continuationFunction = makeContinueFunction(this);
  };

  /** Wait for a job, call the returned function when done. */
  CollectiveContinuation.prototype.makeContinue = function (cont) {
    this.waitCount++;

    if (cont) {
      var contFn = this.continuationFunction;
      return function () {
        cont();
        contFn();
      };
    } else {
      return this.continuationFunction;
    }
  };

  CollectiveContinuation.prototype.tryContinue = function () {
    if (this.waitCount <= 0) {
      this.cont();

      // reset
      this.cont = undefined;
      this.waitCount = 0;
    }
  };

  CollectiveContinuation.prototype.waitFor = function (continuation) {
    var done = this.makeContinue();
    return function() {
      continuation.apply(window, arguments);
      done();
    };
  };

  return CollectiveContinuation;
});

