/* Sectional Anatomy Viewer
 *
 * Copyright (C) 2016 Tobias Rautenkranz
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

define('util/string-distance',["util/array"], function (array) {
  "use strict";

  function levenshteinDistanceImpl(string1, string2, maxDistance, v0, v1) {
    if (string1.length === 0) {
      return string2.length;
    }
    if (string2.length === 0) {
      return string1.length;
    }

    for (var i=0; i < v0.length; i++) {
      v0[i] = i;
    }

    for (var i=0; i < string1.length; i++) {
      v1[0] = i + 1;

      var min = maxDistance + 1;

      for (var j=0; j < string2.length; j++) {
        var cost = (string1[i] == string2[j]) ? 0 : 1;
        v1[j + 1] = Math.min(v1[j] + 1, v0[j + 1] + 1, v0[j] + cost);
        min = Math.min(min, v1[j + 1]);
      }
      if (min >= maxDistance) {
        return min;
      }

      array.set(v0, v1);
    }

    return v1[string2.length];
  }

  function levenshteinDistance(string1, string2, maxDistance) {
    maxDistance = maxDistance || string2.length;

    var v0 = array.mkUint32Array(string2.length + 1);
    var v1 = array.mkUint32Array(string2.length + 1);

    return levenshteinDistanceImpl(string1, string2, maxDistance, v0, v1);
  }

  var StringDistance = function (string, maxDistance) {
    this.string = string;
    this.maxDistance = maxDistance;

    this.v0 = array.mkUint32Array(string.length + 1);
    this.v1 = array.mkUint32Array(string.length + 1);
  };

  StringDistance.prototype.distance = function (string) {
    return levenshteinDistanceImpl(string, this.string, this.maxDistance, this.v0, this.v1);
  };

  StringDistance.distance = levenshteinDistance;

  return StringDistance;
});

