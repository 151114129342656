/* Sectional Anatomy Study Browser
 *
 * Copyright (C) 2012 - 2015 Tobias Rautenkranz
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

define('studies',["util/utils", "util/cont"],
  function (utils, CollectiveContinuation) {
    "use strict";

    function getStudies(studiesElement) {
      var studiesList = studiesElement.children;
      utils.assert(studiesList, "no studies");

      var studies = [];
      for (var i=0; i<studiesList.length; i++) {
        var studyNode = studiesList[i].children[0];
        studies[i] = { url: studyNode.href,
          id: i,
          name: studyNode.children[0].innerHTML };
      }

      return studies;
    }

    function getLabels (study, language, cont) {
      function convertUnderscores(label) {
        return label.replace(/_/g, " ");
      }
      if (!study.labels) {
        utils.loadJSONFile(study.url + "/labels_" + language + ".json",
            function (labels) {
              study.labels = labels.map(convertUnderscores);
              cont(labels);
            },
            // should be studyDataVersion, but we don't bother fetching
            // the studies.json file.
            utils.studiesDataVersion);
      } else {
        cont(study.labels);
      }
    }

    /** Returns the Studies whose description matches searchString. */
    function findStudies(studies, searchString) {
      var searchStrings = utils.splitSearchString(searchString);
      var accessor = function (s){ return s.name; };
      return utils.findSearchString(searchStrings, studies, accessor).ids;
    }

    /** Returns the searchStrings not found in text.
     * Duplicates are only considered once. */
    function complementSearchStrings(searchStrings, text) {
      var complement = [];
      var lastSearch = "";
      searchStrings.sort();
      for (var i=0, length=searchStrings.length; i<length; i++) {
        var search = searchStrings[i];
        var regexp = new RegExp("\\b"+search, "i");
        if (lastSearch === search || !regexp.test(text)) {
          complement.push(search);
        } else {
          lastSearch = search;
        }
      }
      return complement;
    }

    /** Search the anatomy labels of a study. */
    function searchLabel(searchStrings, study, language, cont, done) {
      getLabels(study, language, function (labels) {
        var searchResults = utils.findSearchString(searchStrings, labels);

        cont(searchStrings, searchResults, study, labels);

        done();
      });
    }

    function filterStudies(searchString, studies, language,
        processResults, done) {

      var cont = new CollectiveContinuation(done);
      var loopDone = cont.makeContinue();

      var searchStrings = utils.splitSearchString(searchString);
      for (var i=0; i<studies.length; i++) {
        searchLabel(complementSearchStrings(searchStrings, studies[i].name),
            studies[i], language, processResults, cont.makeContinue());
      }

      loopDone();
    }

    return {
      getStudies: getStudies,
      getLabels: getLabels,
      findStudies: findStudies,
      filterStudies: filterStudies
    };
  });

