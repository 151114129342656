/* Sectional Anatomy Study Browser
 *
 * Copyright (C) 2013 Tobias Rautenkranz
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

define('image-changer',["ui/animate"],
  function (animate) {
    "use strict";
    var changeInterval = 8000;
    var setup = function (imageStack) {
      var images = imageStack.children;
      if (!images.length) {
        return;
      }

      var activeImage = 0;
      images[0].style.zIndex = 1;
      images[0].style.opacity = 1;
      for (var i=0; i<images.length; i++) {
        animate.setTransition(images[i], "opacity 0.4s ease-in");
      }

      var change = function () {
        images[activeImage].style.opacity = 0.0;

        activeImage = (activeImage + 1) % images.length;
        images[activeImage].style.zIndex = 1;
        images[activeImage].style.opacity = 1.0;
      };
      var changerID;
      var start = function () {
        if (!changerID) {
          changerID = setInterval(change, changeInterval);
        }
      };
      var stop = function () {
        if (changerID) {
          clearInterval(changerID);
          changerID = null;
        }
      };

      start();
    };
    var imageStack = document.getElementById("headingImages");
    if (imageStack) {
      setup(imageStack);
    }

    return null;
  });

