/* Sectional Anatomy Study Browser
 *
 * Copyright (C) 2016 Tobias Rautenkranz
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

define('ui/suggestions',["studies", "util/utils", "util/string-distance"],
  function (sstudies, utils, StringDistance) {
    "use strict";

    function suggestionForLabelString(string, labelStrings) {
      var best = { distance: Infinity };
      for (var i=0; i<labelStrings.length; i++) {
        var d = string.distance(labelStrings[i].toLowerCase());
        if (d < best.distance) {
          best.distance = d;
          best.value = labelStrings[i];
        }
      }

      return best;
    }

    function suggestionForLabels(strings, labels) {
      var best = { distance: Infinity };

      var maxDistance = utils.reduce(strings, function (p, s)
          { return p + s.maxDistance; }, 0);

      for (var i=1; i<labels.length; i++) {
        var s = utils.splitSearchString(labels[i]);

        var current = {
          distance: 0, // cumulated distance
          value: []
        };

        for (var j=0; j<strings.length; j++) {
          var sug = suggestionForLabelString(strings[j], s);

          current.distance += sug.distance;
          current.value.push(sug.value);

          if (current.distance > maxDistance) break;
        }

        if (current.distance < best.distance) {
          best.distance = current.distance;
          best.value = current.value;
        }
      }

      return best;
    }

    function maxDistanceForSearchString(string) {
      return Math.floor(1 + 0.4 * string.length);
    }
    function maxDistanceForSearchStrings(strings) {
      return utils.reduce(strings, function (prev, string) {
        return prev + maxDistanceForSearchString(string);
      }, 0);
    }

    function makeProcessLabels(searchStrings, onSuggest, onNoSuggest) {
      var strings = [];
      for (var i=0; i<searchStrings.length; i++) {
        strings[i] = new StringDistance(searchStrings[i].toLowerCase(),
            maxDistanceForSearchString(searchStrings[i]));
      }
      var best = { distance: Infinity };

      return function (labels) {
        var suggestion = suggestionForLabels(strings, labels);
        if (suggestion.distance < best.distance) {
          best = suggestion;
        }

        if (best.value && best.distance < maxDistanceForSearchStrings(best.value)) {
          onSuggest(best.value);
        } else {
          onNoSuggest();
        }
      };
    }

    function makeSuggestionElement(parentElement, onaccept) {
      var div = document.createElement("div");
      var label = document.createElement("span");
      label.innerHTML = "did you mean: ";
      label.style.color = "red";

      var currentSuggestions;

      var suggestionsElement = document.createElement("a");
      suggestionsElement.href = "";
      suggestionsElement.addEventListener("click", function (e) {
        onaccept(currentSuggestions.join(" "));

        e.preventDefault();
      });

      div.appendChild(label);
      div.appendChild(suggestionsElement);
      parentElement.appendChild(div);

      div.style.display = "none";

      return  {
        show: function (suggestions, searchStrings) {
          utils.assert(suggestions.length == searchStrings.length);

          currentSuggestions = suggestions;

          var result = "";
          for (var i=0; i<suggestions.length; i++) {
            if (i !== 0) {
              result += " ";
            }

            if (suggestions[i].toLowerCase() === searchStrings[i].toLowerCase()) {
              result += suggestions[i];
            } else {
              result += "<b>";
              result += suggestions[i];
              result += "</b>";
            }
          }
          suggestionsElement.innerHTML = result;
          div.style.display = "";
        },
        hide: function () {
          div.style.display = "none";
        }
      };
    }

    return {
      makeProcessLabels: makeProcessLabels,
      makeDisplayElement: makeSuggestionElement
    };

});

