/* Sectional Anatomy Viewer
 *
 * Copyright (C) 2012 - 2016 Tobias Rautenkranz
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
define('ui/animate',[],function () {
  "use strict";

  var animationSupported = false;
  var detectedAnimationSupport;

  function addPrefix(prefix, name) {
    var nameFirst = name[0];
    var nameRest = name.slice(1);
    var ret = prefix +
      ("" === prefix ? nameFirst.toLowerCase() :
                       nameFirst.toUpperCase()) + nameRest;
    return ret;
  }

  function checkAnimationSupport () {
    var w3c = {
      prefix: "",
      animationEnd: "animationend"
    };
    var webkit = {
      prefix: "webkit",
      animationEnd: "webkitAnimationEnd" // WTF webkit?
    };
    var browsersAnimation = [w3c, webkit];
    var element = document.createElement("div");
    for (var i=0; i<browsersAnimation.length; i++) {
      var prefix = browsersAnimation[i].prefix;
      if (element.style[addPrefix(prefix, "animationName")] !== undefined) {
        animationSupported = true;
        detectedAnimationSupport = browsersAnimation[i];
        return true;
      }
    }

    return false;
  }
  checkAnimationSupport();

  /* Fallback */
  var hideOnAnimationEnd = function (element) {
    element.style.display = "none";
  };
  var noop = function noop () {};
  var setTransition = noop;
  var setAnimationProperty = noop;
  var setAnimation = noop;


  if (animationSupported) {
    var withPrefix = function (name) {
      return addPrefix(detectedAnimationSupport.prefix, name);
    };

    var animationEndEvent = detectedAnimationSupport.animationEnd;
    // this fails miserably when animationName is not correct!
    hideOnAnimationEnd = function (element, animationName) {
      var removeElement = function () {
        if (animationName === element.style[withPrefix("animationName")]) {
          element.style.display = "none";
        }
        element.removeEventListener(animationEndEvent, removeElement);
      };
      element.addEventListener(animationEndEvent, removeElement);
    };

    var transitionProperty = withPrefix("transition");
    setTransition = function (element, transition) {
      element.style[transitionProperty] = transition;
    };

    setAnimationProperty = function (element, animation) {
      element.style[withPrefix("animation")] = animation;
    };
    setAnimation = function (element, properties) {
      function beginUppercase(string) {
        return string[0].toUpperCase() + string.slice(1);
      }
      var prefix = withPrefix("animation");
      for (var p in properties) {
        element.style[prefix+beginUppercase(p)] = properties[p];
      }
    };
  }


  return {
    supported: animationSupported,
    prefix: detectedAnimationSupport ?
      detectedAnimationSupport.prefix : undefined,

    setAnimationProperty: setAnimationProperty,
    setAnimation: setAnimation,
    setTransition: setTransition,
    hideOnAnimationEnd: hideOnAnimationEnd
  };
});

