/* Sectional Anatomy Study Browser
 *
 * Copyright (C) 2012 - 2017 Tobias Rautenkranz
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

requirejs.config({
    urlArgs: "v=1.4.7",
  //>>includeStart("debugInclude", pragmas.debug);
    urlArgs: "bust=" +  (new Date()).getTime(),
  //>>includeEnd("debugInclude");

  baseUrl: "js/"
});

requirejs(["studies-browser", "image-changer"],
  function (studies) {
    "use strict";
    studies.init();
  });

define("studies-main", function(){});

